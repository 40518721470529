<script lang="ts">
    import { onMount } from 'svelte';

    export let post: any;
    export let currentUser: any;
    export let siteSettings: any;
    export let data: any;

    const CHAR_LIMIT = 400;
    let isFullDescription = false;
    let postElement: HTMLElement;

    $: isConverting =
        currentUser &&
        ((post.user_id === currentUser.id && post.status == 0 && data.hasVideoAttachment) ||
            (currentUser.role_id === 1 && post.status == 0 && data.hasVideoAttachment));

    $: shouldShowMoreInfo = post.text.length > CHAR_LIMIT;

    function toggleFullDescription() {
        isFullDescription = !isFullDescription;

        if (postElement && !isFullDescription) {
            setTimeout(() => scrollToPostStart(), 0);
        }
    }

    function scrollToPostStart() {
        if (postElement) {
            const yOffset = -60;
            const y = postElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    onMount(() => {
        postElement = document.querySelector(`*[data-postID="${post.id}"]`);
    });
</script>

<div
    class="post-content mt-3 pl-3 pr-3"
    class:converting="{isConverting}"
    data-postID="{post.id}"
    bind:this="{postElement}"
>
    <p class="text-break post-content-data">
        {#if shouldShowMoreInfo && !isFullDescription}
            {post.text.slice(0, CHAR_LIMIT)}...
        {:else}
            {post.text}
        {/if}

        {#if shouldShowMoreInfo}
            <span class="cursor-pointer text-primary" on:click="{toggleFullDescription}">
                {#if isFullDescription}
                    Show less
                {:else}
                    Show more
                {/if}
            </span>
        {/if}
    </p>
</div>
